//
// Flash messages
//

window.addEventListener && window.addEventListener('load', function() {

    let alerts = document.querySelectorAll('.flash-messages-fixed .alert');

    alerts.forEach((alert) => {
        let closeButton = alert.querySelector('.close');

        if (closeButton) {

            closeButton.addEventListener('click', function(e) {
                e.preventDefault();

                alert.classList.add('fadeout-delay-0');

                setTimeout(() => {
                    alert.remove();
                }, 500);
            });

        } else {

            alert.classList.add('fadeout-delay-5000');

            setTimeout(() => {
                alert.remove();
            }, 5500);

        }
    });

});
